import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  development: {
    backgroundColor: 'green',
  },
  staging: {
    backgroundColor: 'green',
  },
  production: {
    backgroundColor: 'red',
  },
  envName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar
      {...props}
      className={classes[process.env.REACT_APP_ENVIRONMENT_NAME]}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/*<Logo />*/}
      <span className={classes.envName}>
        {process.env.REACT_APP_ENVIRONMENT_NAME}
      </span>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
