import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_PUBLIC_API_URL;
const httpClient = (url, options = {}) => {
  options = {
    ...options,
    credentials: 'include',
  };

  return fetchUtils.fetchJson(url, options).catch((error, b) => {
    if (error.status === 401) {
      window.location = process.env.REACT_APP_FRONT_URL;
    }
    throw new Error(error);
  });
};

const singularizeResource = (string) => string.slice(0, string.length - 1);

const isLiteralObject = (a) => {
  return !!a && a.constructor === Object;
};

const provider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      order_by: field,
      direction: order,
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      page,
      per_page: perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('Total-Count'), 10) || json.length,
    }));
  },

  getOne: async (resource, params) => {
    return await httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    );
  },

  getMany: (resource, params) => {
    console.log(resource, params);
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    console.log(url);
    return httpClient(url).then(({ json }) => {
      console.log(json);
      return { data: json };
    });
  },

  update: (resource, params) => {
    console.log(params);

    if (params.data.error) {
      return Promise.reject(new Error(params.data.error.message));
    }

    const formData = new FormData();

    for (const name in params.data) {
      if (isLiteralObject(params.data[name])) {
        for (const nestedName in params.data[name]) {
          if (
            !params.data[name][nestedName] &&
            params.data[name][nestedName] !== false &&
            params.data[name][nestedName] !== 0 &&
            params.data[name][nestedName] !== null
          )
            continue;

          formData.append(
            `${singularizeResource(resource)}[${name}][${nestedName}]`,
            params.data[name][nestedName]
          );
        }
      } else {
        if (
          !params.data[name] &&
          params.data[name] !== false &&
          params.data[name] !== 0 &&
          params.data[name] !== null
        )
          continue;

        formData.append(
          `${singularizeResource(resource)}[${name}]`,
          params.data[name]
        );
      }
    }

    if (params.data.image) {
      formData.delete(`${singularizeResource(resource)}[image]`);

      formData.set(
        `${singularizeResource(resource)}[image]`,
        params.data.image.rawFile
      );
    }

    if (params.data.secondary_images) {
      formData.delete(`${singularizeResource(resource)}[secondary_images]`);

      params.data.secondary_images.forEach((secondaryImage, index) => {
        formData.append(
          `${singularizeResource(resource)}[secondary_images][]`,
          secondaryImage.rawFile
        );
      });
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const urlParams = new URLSearchParams();
    params.ids.forEach((id) => urlParams.append('id[]', id));

    return httpClient(`${apiUrl}/${resource}?${urlParams.toString()}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params = {}) => {
    console.log(params);

    if (params.data.error) {
      return Promise.reject(new Error(params.data.error.message));
    }

    const formData = new FormData();

    for (const name in params.data) {
      if (isLiteralObject(params.data[name])) {
        for (const nestedName in params.data[name]) {
          if (
            !params.data[name][nestedName] &&
            params.data[name][nestedName] !== false &&
            params.data[name][nestedName] !== 0 &&
            params.data[name][nestedName] !== null
          )
            continue;

          formData.append(
            `${singularizeResource(resource)}[${name}][${nestedName}]`,
            params.data[name][nestedName]
          );
        }
      } else {
        if (
          !params.data[name] &&
          params.data[name] !== false &&
          params.data[name] !== 0 &&
          params.data[name] !== null
        )
          continue;

        formData.append(
          `${singularizeResource(resource)}[${name}]`,
          params.data[name]
        );
      }
    }

    if (params.data.image) {
      formData.delete(`${singularizeResource(resource)}[image]`);

      formData.set(
        `${singularizeResource(resource)}[image]`,
        params.data.image.rawFile
      );
    }

    if (params.data.secondary_images) {
      formData.delete(`${singularizeResource(resource)}[secondary_images]`);

      params.data.secondary_images.forEach((secondaryImage, index) => {
        formData.append(
          `${singularizeResource(resource)}[secondary_images][]`,
          secondaryImage.rawFile
        );
      });
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const urlParams = new URLSearchParams();
    params.ids.forEach((id) => urlParams.append('id[]', id));

    return httpClient(`${apiUrl}/${resource}?${urlParams.toString()}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
};

export default provider;
