import {
  List,
  Datagrid,
  ImageField,
  TextField,
  BooleanField,
  DateField,
  NumberField,
  Edit,
  Show,
  Create,
  SimpleForm,
  ImageInput,
  BooleanInput,
  useRedirect,
  SelectInput,
  CheckboxGroupInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  DateTimeInput,
  FormDataConsumer,
  useGetOne,
  SaveButton,
  ArrayInput,
  Pagination,
  SimpleFormIterator,
  Toolbar,
  useNotify,
  ReferenceField,
  useRefresh,
  FormWithRedirect,
  TopToolbar,
  Button,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  useCreate,
  useUpdate,
} from 'react-admin';
import { Link, useHistory, useLocation } from 'react-router-dom';
import dataProvider from '../api/provider';
import { useFormState } from 'react-final-form';
import useTransform from '../utils/useTransform';
import useOnSubmitFailure from '../utils/useOnSubmitFailure';
import TruckIcon from '@material-ui/icons/LocalShipping';
import { Typography, Box } from '@material-ui/core';

const { getOne } = dataProvider;

const filters = [
  <TextInput source="users.name" label="User Name" alwaysOn />,
  <TextInput source="users.email" label="User Email" alwaysOn />,
  <SelectInput
    source="gateway"
    choices={[
      { id: 'pay_pal', name: 'PayPal' },
      { id: 'stripe', name: 'Stripe' },
    ]}
  />,
  <CheckboxGroupInput
    source="type"
    choices={[
      { id: 'Order::Recharge', name: 'Order::Recharge' },
      { id: 'Order::Purchase', name: 'Order::Purchase' },
      { id: 'Order::BuyBack', name: 'Order::BuyBack' },
      { id: 'Order::BidExchange', name: 'Order::BidExchange' },
    ]}
  />,
  <CheckboxGroupInput
    source="status"
    choices={[
      { id: 'initialized', name: 'Initialized' },
      { id: 'checkout_started', name: 'Checkout_started' },
      { id: 'fulfilled', name: 'Fulfilled' },
      { id: 'shipped', name: 'Shipped' },
      { id: 'canceled', name: 'Canceled' },
      { id: 'errored', name: 'Errored' },
      { id: 'expired', name: 'Expired' },
    ]}
  />,
];

const OrdersPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150, 200]} {...props} />
);

const OrderEditButton = (props) => {
  if (props.record.type === 'Order::Recharge') return null;

  return <EditButton {...props} />;
};

export const OrderList = (props) => (
  <List
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={filters}
    pagination={<OrdersPagination />}
    perPage={50}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="gateway" />
      <TextField source="integration_order_id" />
      <ReferenceField label="User" source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Purchase Ready"
        source="user_id"
        reference="users"
        sortable={false}
      >
        <TextField source="purchase_ready?" />
      </ReferenceField>
      <TextField source="type" />
      <ReferenceField label="Deal" source="deal_id" reference="deals">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="status_code" sortBy="status" />
      <NumberField
        source="pesos"
        label="Pesos"
        options={{ style: 'currency', currency: 'MXN' }}
      />
      <NumberField
        source="cost"
        label="Costo"
        options={{ style: 'currency', currency: 'MXN' }}
      />
      <DateField
        source="paid_at"
        label="paid_at"
        showTime
        sortBy="paid_at"
        options={{
          dateStyle: 'full',
          timeStyle: 'medium',
          hour12: true,
        }}
      />
      <DateField
        source="original_created_at"
        label="created_at"
        showTime
        sortBy="created_at"
        options={{
          dateStyle: 'full',
          timeStyle: 'medium',
          hour12: true,
        }}
      />
      <DateField
        source="shipped_at"
        label="Enviada el"
        showTime
        sortBy="shipped_at"
        options={{
          dateStyle: 'full',
          timeStyle: 'medium',
          hour12: true,
        }}
      />
      <TextField source="tracking_number" />
      <TextField source="user_facing_notes" label="Notas a cliente" />
      <OrderEditButton></OrderEditButton>
      <ShowButton />
    </Datagrid>
  </List>
);

const ShipButton = ({ record, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const {
    data: user,
    loading: userLoading,
    error: userError,
  } = useGetOne('users', record.user_id);
  const [create, { loading: createLoading, error: createError }] = useCreate(
    `orders/${record.id}/shipments`,
    { order_id: record.id }
  );

  const handleClick = async (event) => {
    if (!user?.is_purchase_ready) {
      notify('El usuario no está purchase_ready', 'warning');
      return;
    } else if (record.status_code === 'shipped') {
      notify('La orden ya ha sido enviada', 'warning');
      return;
    }

    await create();
    notify(
      'La orden se ha marcado como enviada al domicilio actual',
      'success'
    );
    refresh();
  };

  if (record.type === 'Order::Recharge') {
    return null;
  }

  if (createError || userError) {
    return <p>ERROR - Refrescar</p>;
  }

  if (userLoading || createLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Button
        disabled={
          !user?.is_purchase_ready ||
          createLoading ||
          record.status_code === 'shipped'
        }
        onClick={handleClick}
        color="primary"
      >
        <Box display="flex" alignItems="center">
          {(record.status_code === 'shipped' && 'ENVIADA') || 'SHIP'}{' '}
          <TruckIcon></TruckIcon>
        </Box>
      </Button>
      {!user?.is_purchase_ready && (
        <p style={{ color: 'red' }}>El usuario no está purchase_ready</p>
      )}
    </>
  );
};

const TrackingNumberInput = ({ record }) => {
  if (record.type === 'Order::Recharge') return null;

  return <TextInput source="tracking_number" />;
};

const AddressField = ({ record }) => {
  if (record.type === 'Order::Recharge') return null;

  return (
    <Box display="flex" flexDirection="column">
      <label>Enviada a</label>
      <TextField source="address" label="Enviada a" />
    </Box>
  );
};

export const OrderShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="gateway" />
        <TextField source="integration_order_id" />
        <TextField source="status" />
        <TextField source="status_code" sortBy="status" />
        <TextField source="status_reason" />
        <TextField source="tracking_number" />
        <NumberField
          source="pesos"
          label="Pesos"
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source="cost"
          label="Costo"
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <DateField
          source="paid_at"
          label="paid_at"
          showTime
          sortBy="paid_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <DateField
          source="original_created_at"
          label="created_at"
          showTime
          sortBy="created_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <DateField
          source="shipped_at"
          label="Enviada el"
          showTime
          sortBy="shipped_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <ReferenceField label="User" source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Purchase Ready"
          source="user_id"
          reference="users"
          sortable={false}
        >
          <TextField source="purchase_ready?" />
        </ReferenceField>
        <TextField source="type" />
        <ReferenceField label="Deal" source="deal_id" reference="deals">
          <TextField source="title" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export const OrderEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="edit">
        <TrackingNumberInput></TrackingNumberInput>
        <TextInput
          source="user_facing_notes"
          label="Notas a cliente"
          inputProps={{ autocomplete: 'off' }}
        />
        <TextInput source="comments" inputProps={{ autocomplete: 'off' }} />
        <NumberInput source="cost" />

        <ShipButton></ShipButton>

        <AddressField></AddressField>

        <TextField source="id" />
        <TextField source="gateway" />
        <TextField source="integration_order_id" />
        <TextField source="status" />
        <TextField source="status_code" sortBy="status" />
        <TextField source="status_reason" />
        <NumberField
          source="pesos"
          label="Pesos"
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <DateField
          source="paid_at"
          label="paid_at"
          showTime
          sortBy="paid_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <DateField
          source="original_created_at"
          label="created_at"
          showTime
          sortBy="created_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <DateField
          source="shipped_at"
          label="Enviada el"
          showTime
          sortBy="shipped_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <ReferenceField label="User" source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Purchase Ready"
          source="user_id"
          reference="users"
          sortable={false}
        >
          <TextField source="purchase_ready?" />
        </ReferenceField>
        <TextField source="type" />
        <ReferenceField label="Deal" source="deal_id" reference="deals">
          <TextField source="title" />
        </ReferenceField>
      </SimpleForm>
    </Edit>
  );
};
