import {
  List,
  Datagrid,
  ImageField,
  TextField,
  BooleanField,
  DateField,
  NumberField,
  Edit,
  Create,
  SimpleForm,
  ImageInput,
  BooleanInput,
  CheckboxGroupInput,
  SelectInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  EditButton,
  ShowButton,
  DateTimeInput,
  FormDataConsumer,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  useNotify,
  SearchInput,
  DeleteButton,
  Pagination,
} from 'react-admin';
import { Link, useHistory, useLocation } from 'react-router-dom';
import dataProvider from '../api/provider';
import { useFormState } from 'react-final-form';
import useTransform from '../utils/useTransform';
import useOnSubmitFailure from '../utils/useOnSubmitFailure';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  userImage: {
    '& img': {
      height: '75px',
      height: '75px',
      objectFit: 'contain',
    },
  },
});

const { getOne } = dataProvider;

const filters = [
  <TextInput label="Email" source="email" />,
  <TextInput label="Name" source="name" alwaysOn />,
  <SelectInput
    source="Online Status"
    choices={[
      { id: 'online', name: 'Online' },
      { id: 'offline', name: 'Offline' },
      { id: 'away', name: 'Away' },
    ]}
    alwaysOn
  />,
];

const UsersPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150, 200]} {...props} />
);

const UserDeleteButton = (props) => {
  if (!props.record.auto_bot) return null;

  return <DeleteButton {...props} />;
};

export const UserList = (props) => {
  const classes = useStyles();

  return (
    <List
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={filters}
      pagination={<UsersPagination />}
      perPage={50}
      {...props}
    >
      <Datagrid>
        <ImageField
          source="image_url.url"
          title={props.email}
          sortable={false}
          label="Image"
          className={classes.userImage}
        />
        <TextField source="name" />
        <TextField source="email" />
        <NumberField
          source="credits"
          label="Bids"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'BID',
          }}
        />
        <TextField source="online_status" />
        <DateField
          source="created_at"
          showTime
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <BooleanField source="banned" />
        <BooleanField source="auto_bot" label="Auto Diego" />
        <BooleanField source="manual_bot" label="Manual Diego" />
        <EditButton />
        <ShowButton />
        <UserDeleteButton></UserDeleteButton>
      </Datagrid>
    </List>
  );
};

export const UserEdit = (props) => {
  const notify = useNotify();
  const onFailure = useOnSubmitFailure(notify);
  const transformFn = useTransform();

  const transform = async (data) => {
    data = await transformFn(data);

    return {
      ...data,
      address_attributes: {
        street: data.street,
        external_number: data.external_number,
        internal_number: data.internal_number,
        city: data.city,
        state: data.state,
        suburb: data.suburb,
        zip_code: data.zip_code,
        between_streets: data.between_streets,
        delivery_instructions: data.delivery_instructions,
      },
    };
  };

  return (
    <Edit {...props} transform={transform} onFailure={onFailure}>
      <SimpleForm>
        <DateField
          source="created_at"
          label="Creado el"
          showTime
          sortBy="created_at"
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="image_url.url" />
        <ImageInput source="image_url" accept="image/*">
          <ImageField source="url" />
        </ImageInput>
        <NumberInput source="credits" />
        <NumberInput source="referral_credits" />
        <BooleanInput source="banned" />
        <TextField source="purchase_ready?" sortable={false} />
        <TextInput source="banned_reason" />
        <TextInput source="phone" />
        <TextInput source="street" />
        <TextInput source="external_number" />
        <TextInput source="internal_number" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="suburb" />
        <TextInput source="zip_code" />
        <TextInput source="between_streets" />
        <TextInput source="delivery_instructions" />
        <BooleanField source="auto_bot" label="Auto Diego" />
        <BooleanField source="manual_bot" label="Manual Diego" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => {
  const notify = useNotify();
  const onFailure = useOnSubmitFailure(notify);
  const transform = useTransform();

  return (
    <Create {...props} transform={transform} onFailure={onFailure}>
      <SimpleForm redirect="list">
        <TextInput source="name" inputProps={{ autocomplete: 'off' }} />

        <BooleanInput
          source="auto_bot"
          label="Auto Diego"
          defaultValue={true}
          disabled={true}
        />
      </SimpleForm>
    </Create>
  );
};
