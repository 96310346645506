import imageUrlsToFiles from './imageUrlsToFiles';

export default function useTransform() {
  return async function (data) {
    try {
      return await imageUrlsToFiles(data);
    } catch (error) {
      return { error };
    }
  };
}
