import { useGetList, RadioButtonGroupInput, DateTimeInput } from 'react-admin';
import { useState } from 'react';
import groupBy from 'lodash.groupby';
import set from 'date-fns/set';
import format from 'date-fns/format';
import { makeStyles } from '@material-ui/core/styles';

// TODO: Use this for stlying
const useStyles = makeStyles({});

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

const StartAt = ({ record, ...props }) => {
  const today = format(
    set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
    'yyyy-MM-dd'
  );
  const [date, setDate] = useState(today);

  let {
    data: availableTimes,
    loading: availableTimesLoading,
    error: availableTimesError,
  } = useGetList(`deals/available_times`, {}, {}, { date: date }, {});

  const startAtFormatter = new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const dayFormatter = new Intl.DateTimeFormat('default', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  availableTimes = Object.values(availableTimes).map(({ id, start_at }) => ({
    id,
    name: startAtFormatter.format(new Date(start_at)),
  }));

  availableTimes = groupBy(availableTimes, (e) =>
    set(new Date(e.id), { hours: 0, minutes: 0, seconds: 0 })
  );

  const [isChoiced, setIsChoiced] = useState(true);
  const onClick = (event) => {
    event.preventDefault();

    setIsChoiced((prev) => !prev);
  };
  const onChange = (event) => {
    setDate(event.target.value);
  };
  const buttonText =
    (isChoiced && 'Usar hora personalizada') || 'Usar opciones';

  if (availableTimesError)
    return (
      <div>
        <div>Error al cargar horarios, usar hora personalizada</div>
        <DateTimeInput
          source="start_at"
          initialValue={addMinutes(new Date(), 1)}
        />
      </div>
    );
  if (availableTimesLoading) return <div>Cargando horarios...</div>;

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <button
          onClick={onClick}
          style={{
            padding: '0.5rem',
          }}
        >
          {buttonText}
        </button>
      </div>

      {(isChoiced && (
        <div>
          <div>
            <label htmlFor="_date">Día para horarios</label>
          </div>
          <div>
            <input
              name="_date"
              type="date"
              style={{
                padding: '0.5rem',
                marginBottom: '1rem',
              }}
              min={today}
              onChange={onChange}
              value={date}
            />
          </div>

          {!Object.keys(availableTimes).length && (
            <div style={{ margin: '1rem 0' }}>
              No hay horarios predeterminados disponibles para el día
              seleccionado. Intenta con otro, quita el día seleccionado para ver
              los próximos 7 días o cambia a selección de hora personalizada.
            </div>
          )}

          {Object.keys(availableTimes).map((dayString) => {
            return (
              <section key={dayString}>
                <div style={{ fontWeight: 'bold' }}>
                  {dayFormatter.format(new Date(dayString))}
                </div>
                <div>
                  <RadioButtonGroupInput
                    label="start_at"
                    source="start_at"
                    choices={availableTimes[dayString]}
                  ></RadioButtonGroupInput>
                </div>
              </section>
            );
          })}
        </div>
      )) || (
        <DateTimeInput
          source="start_at"
          initialValue={addMinutes(new Date(), 1)}
        />
      )}
    </div>
  );
};

export default StartAt;
