export default function useOnSubmitFailure(notify) {
  if (!notify) {
    throw new Error('Missing notify function.');
  }

  return function (error) {
    let message;

    if (error.message === 'Failed to fetch') {
      message = `No se puede obtener la imagen de la URL. Prueba subiendo la imagen
    directamente.`;
    } else {
      message = `No se puede crear el producto: ${error.message}`;
    }

    notify(message, 'warning');
  };
}
