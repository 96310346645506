import {
  List,
  Datagrid,
  ImageField,
  TextField,
  BooleanField,
  DateField,
  NumberField,
  Edit,
  Create,
  SimpleForm,
  ImageInput,
  BooleanInput,
  SelectInput,
  ReferenceField,
  TextInput,
  DateTimeInput,
  NumberInput,
  EditButton,
  Pagination,
  ArrayInput,
  SimpleFormIterator,
  Link,
  useNotify,
  useRefresh,
  useRedirect,
  UrlField,
} from 'react-admin';
import TimeAgoField from '../fields/TimeAgoField';
import Button from '@material-ui/core/Button';
import useTransform from '../utils/useTransform';
import useOnSubmitFailure from '../utils/useOnSubmitFailure';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  productImage: {
    '& img': {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
    },
  },
  truncatedText: {
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
});

const filters = [<TextInput label="Title" source="title" alwaysOn />];

const ProductsPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150, 200]} {...props} />
);

export const ProductList = (props) => {
  // To use this, just render this button at the end of the List.
  //
  // It will create a Create Deal button
  //
  const CreateRelatedDealButton = ({ record }) => {
    return (
      <Button
        component={Link}
        to={{
          pathname: '/deals/create',
          state: {
            record: {
              ...record,
              product_id: record.id,
              original_price: record.price,
              one_per_bidder: record.one_per_bidder,
            },
          },
        }}
      >
        Create Deal
      </Button>
    );
  };

  const classes = useStyles();

  return (
    <List
      sort={{ field: 'price', order: 'ASC' }}
      filters={filters}
      pagination={<ProductsPagination />}
      perPage={50}
      {...props}
    >
      <Datagrid>
        <ImageField
          source="image_url.url"
          title={props.title}
          className={classes.productImage}
        />
        <TextField source="title" />
        <TimeAgoField source="last_deal_start_at" />
        <UrlField
          source="url"
          label="Product URL"
          className={classes.truncatedText}
        />
        <NumberField
          source="price"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'MXN',
          }}
        />
        <EditButton />
        <CreateRelatedDealButton />
        <BooleanField source="one_per_bidder" />
        <BooleanField source="only_for_noobs" />
      </Datagrid>
    </List>
  );
};

export const ProductEdit = withStyles({ card: { overflow: 'initial' } })(
  (props) => {
    const notify = useNotify();
    const onFailure = useOnSubmitFailure(notify);
    const transform = useTransform();

    return (
      <Edit {...props} transform={transform} onFailure={onFailure}>
        <SimpleForm>
          <TextInput source="image_url.url" />

          <ImageInput source="image_url" accept="image/*">
            <ImageField source="url" />
          </ImageInput>

          <ArrayInput source="secondary_image_urls">
            <SimpleFormIterator>
              <TextInput source="url" />

              <ImageInput accept="image/*">
                <ImageField source="url" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source="title" />
          <RichTextInput source="description"></RichTextInput>
          <NumberInput source="price" />
          <TextInput source="url" label="Product URL" />
          <BooleanInput source="one_per_bidder" />
          <BooleanInput source="only_for_noobs" />
        </SimpleForm>
      </Edit>
    );
  }
);

export const ProductCreate = (props) => {
  const notify = useNotify();
  const onFailure = useOnSubmitFailure(notify);
  const transform = useTransform();

  return (
    <Create {...props} transform={transform} onFailure={onFailure}>
      <SimpleForm sanitizeEmptyValues={false}>
        <TextInput source="image_url.url" />

        <ImageInput source="image_url" accept="image/*">
          <ImageField source="url" />
        </ImageInput>

        <ArrayInput source="secondary_image_urls">
          <SimpleFormIterator>
            <TextInput source="url" />

            <ImageInput accept="image/*">
              <ImageField source="url" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source="title" />
        <RichTextInput source="description"></RichTextInput>
        <NumberInput source="price" />
        <TextInput multiline source="url" label="Product URL" />
        <BooleanInput source="one_per_bidder" />
        <BooleanInput source="only_for_noobs" />
      </SimpleForm>
    </Create>
  );
};
