import { useRecordContext } from 'react-admin';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { es } from 'date-fns/locale';
import get from 'lodash.get';

const TimeAgoField = (props) => {
  const record = useRecordContext(props);

  if (!record) return null;

  const timestamp = get(props.record, props.source);

  if (!timestamp) return null;

  return (
    <span>
      {formatDistanceToNowStrict(new Date(timestamp), {
        locale: es,
        weekStartsOn: '1',
        addSuffix: true,
      })}
    </span>
  );
};

export default TimeAgoField;
