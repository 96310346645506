import {
  List,
  Datagrid,
  ImageField,
  TextField,
  BooleanField,
  DateField,
  NumberField,
  Edit,
  Create,
  SimpleForm,
  ImageInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  EditButton,
  ShowButton,
  DateTimeInput,
  ArrayInput,
  Pagination,
  SimpleFormIterator,
  TabbedShowLayout,
  BulkUpdateButton,
  Tab,
  Toolbar,
  Show,
  useNotify,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import dataProvider from '../api/provider';
import useTransform from '../utils/useTransform';
import useOnSubmitFailure from '../utils/useOnSubmitFailure';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StartAt from '../components/StartAt';

const useStyles = makeStyles({
  dealImage: {
    '& img': {
      width: '75px',
      height: '75px',
      objectFit: 'contain',
    },
  },
});

const { getOne } = dataProvider;

const filters = [
  <TextInput label="Title" source="title" alwaysOn />,
  <SelectInput
    source="status"
    allowEmpty={false}
    choices={[
      { id: 'won', name: 'Won' },
      { id: 'closed', name: 'Closed' },
      { id: 'null', name: 'Open' },
    ]}
  />,
  <SelectInput
    source="active"
    allowEmpty={false}
    choices={[
      { id: 'true', name: 'Activo' },
      { id: 'false', name: 'Desactivado' },
    ]}
  />,
];

const DealsPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150, 200]} {...props} />
);

const DealBulkActionButtons = (props) => (
  <>
    <BulkUpdateButton
      {...props}
      label="Desactivar"
      data={{ active: false }}
      icon={<VisibilityOff />}
    />
    <BulkUpdateButton
      {...props}
      label="Activar"
      data={{ active: true }}
      icon={<Visibility />}
    />
  </>
);

export const DealList = (props) => {
  const classes = useStyles();

  return (
    <List
      sort={{ field: 'start_at', order: 'DESC' }}
      filterDefaultValues={{ status: 'null', active: 'true' }}
      filters={filters}
      pagination={<DealsPagination />}
      perPage={50}
      bulkActionButtons={<DealBulkActionButtons />}
      {...props}
    >
      <Datagrid>
        <ImageField
          source="image_url.url"
          title={props.title}
          sortable={false}
          className={classes.dealImage}
        />
        <TextField source="title" />
        <ReferenceField
          label="Last Bidder"
          source="last_bidder_id"
          reference="users"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Current Bidder"
          source="current_bidder_id"
          reference="users"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <DateField
          source="status_at"
          showTime
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <NumberField
          source="original_price"
          label="Buy Back price"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'MXN',
          }}
        />
        <NumberField
          source="price"
          label="Current Price"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'MXN',
          }}
        />
        <DateField
          source="start_at"
          showTime
          options={{
            dateStyle: 'full',
            timeStyle: 'medium',
            hour12: true,
          }}
        />
        <BooleanField source="active" />
        <BooleanField source="mystery" />
        <BooleanField source="one_per_bidder" />
        <BooleanField source="only_for_noobs" />
        <NumberField
          source="bids_needed"
          label="Bids necesarios"
          options={{
            style: 'decimal',
          }}
        />
        <NumberField
          source="extra_time_to_close"
          label="Tiempo para cerrar"
          options={{
            style: 'decimal',
          }}
        />
        <NumberField
          source="bot_bids_to_schedule"
          label="Minimo de bids que agendarán los diegos"
          options={{
            style: 'decimal',
          }}
        />
        <NumberField
          source="number_of_bots_to_use"
          label="Máximo de diegos a usar"
          options={{
            style: 'decimal',
          }}
        />
        <NumberField
          source="price_to_stop_bots"
          label="Precio al que paren los diegos"
          options={{
            style: 'decimal',
          }}
        />
        <BooleanField source="auto_restart" />
        <NumberField
          source="profit_loss"
          label="Profit/Loss"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'MXN',
          }}
        />
        <NumberField
          source="break_even"
          label="Break even"
          options={{
            style: 'currency',
            currencyDisplay: 'code',
            currency: 'BID',
            maximumFractionDigits: 0,
          }}
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const DealShow = (props) => {
  const classes = useStyles();

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Operación">
          <ImageField
            source="image_url.url"
            title={props.title}
            sortable={false}
            className={classes.dealImage}
          />
          <TextField source="title" label="Título" />
          <TextField source="status" />
          <NumberField
            source="bids_needed"
            label="Bids necesarios"
            options={{
              style: 'decimal',
            }}
          />

          <ReferenceField
            label="Last Bidder"
            source="last_bidder_id"
            reference="users"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Current Bidder"
            source="current_bidder_id"
            reference="users"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Ganador Proyectado"
            source="projected_winner_id"
            reference="users"
          >
            <TextField source="name" />
          </ReferenceField>

          <b>Todo</b>
          <hr></hr>

          <NumberField
            source="all_bidders_count"
            label="# de Bideadores"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="all_bidders_bids"
            label="Bids sin usar de bideadores (actual)"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="all_bids_count"
            label="Bids gastados"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="all_remaining_scheduled_bids"
            label="Bids agendados útiles"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="all_unused_bids"
            label="Bids agendados totales"
            options={{
              style: 'decimal',
            }}
          />

          <b>Reales</b>
          <hr></hr>

          <ReferenceArrayField
            label="Bideadores"
            reference="users"
            source="bidders_ids"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <NumberField
            source="real_bidders_count"
            label="# de Bideadores"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="real_bidders_bids"
            label="Bids sin usar de bideadores (actual)"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="real_bids_count"
            label="Bids gastados"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="real_remaining_scheduled_bids"
            label="Bids agendados útiles"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="real_unused_bids"
            label="Bids agendados totales"
            options={{
              style: 'decimal',
            }}
          />

          <b>Diegos</b>
          <hr></hr>

          <ReferenceArrayField
            label="Diegos"
            reference="users"
            source="diegos_ids"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <NumberField
            source="bot_bidders_count"
            label="# de Bideadores"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bot_bidders_bids"
            label="Bids sin usar de bideadores (actual)"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bot_bids_count"
            label="Bids gastados"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bot_remaining_scheduled_bids"
            label="Bids agendados útiles"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bot_unused_bids"
            label="Bids agendados totales"
            options={{
              style: 'decimal',
            }}
          />
        </Tab>
        <Tab label="General">
          <ImageField
            source="image_url.url"
            title={props.title}
            sortable={false}
            className={classes.dealImage}
          />
          <TextField source="title" label="Título" />

          <TextField source="status" />
          <DateField
            source="status_at"
            showTime
            options={{
              dateStyle: 'full',
              timeStyle: 'medium',
              hour12: true,
            }}
          />
          <NumberField
            source="original_price"
            label="Buy Back price"
            options={{
              style: 'currency',
              currencyDisplay: 'code',
              currency: 'MXN',
            }}
          />
          <NumberField
            source="price"
            label="Current Price"
            options={{
              style: 'currency',
              currencyDisplay: 'code',
              currency: 'MXN',
            }}
          />
          <NumberField
            source="profit_loss"
            label="Profit/Loss"
            options={{
              style: 'currency',
              currencyDisplay: 'code',
              currency: 'MXN',
            }}
          />
          <NumberField
            source="break_even"
            label="Break even"
            options={{
              style: 'currency',
              currencyDisplay: 'code',
              currency: 'BID',
              maximumFractionDigits: 0,
            }}
          />
          <DateField
            source="start_at"
            showTime
            options={{
              dateStyle: 'full',
              timeStyle: 'medium',
              hour12: true,
            }}
          />
          <BooleanField source="active" />
          <BooleanField source="mystery" />
          <BooleanField source="one_per_bidder" />
          <BooleanField source="only_for_noobs" />
          <NumberField
            source="extra_time_to_close"
            label="Tiempo para cerrar"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bids_needed"
            label="Bids necesarios"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="bot_bids_to_schedule"
            label="Minimo de bids que agendarán los diegos"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="number_of_bots_to_use"
            label="Máximo de diegos a usar"
            options={{
              style: 'decimal',
            }}
          />
          <NumberField
            source="price_to_stop_bots"
            label="Precio al que paren los diegos"
            options={{
              style: 'decimal',
            }}
          />
          <BooleanField source="auto_restart" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const DealEdit = withStyles({ card: { overflow: 'initial' } })(
  (props) => {
    const notify = useNotify();
    const onFailure = useOnSubmitFailure(notify);
    const transform = useTransform();

    return (
      <Edit {...props} transform={transform} onFailure={onFailure}>
        <SimpleForm redirect="edit">
          <TextInput source="image_url.url" />
          <ImageInput source="image_url" accept="image/*">
            <ImageField source="url" />
          </ImageInput>

          <ArrayInput source="secondary_image_urls">
            <SimpleFormIterator>
              <TextInput source="url" />

              <ImageInput accept="image/*">
                <ImageField source="url" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source="title" />
          <RichTextInput source="description"></RichTextInput>
          <NumberInput source="original_price" />
          <DateTimeInput source="start_at" showTime />
          <BooleanInput source="active" />
          <BooleanInput source="mystery" />
          <BooleanInput source="one_per_bidder" />
          <BooleanInput source="only_for_noobs" />
          <NumberInput source="bids_needed" initialValue={0} />
          <NumberInput source="bot_bids_to_schedule" initialValue={10} />
          <NumberInput source="number_of_bots_to_use" initialValue={2} />
          <NumberInput source="price_to_stop_bots" />
          <BooleanInput source="auto_restart" />
        </SimpleForm>
      </Edit>
    );
  }
);

export const DealCreate = (props) => {
  const history = useHistory();
  const notify = useNotify();
  const onFailure = useOnSubmitFailure(notify);
  const transform = useTransform();

  const onDealProductChange = async (event) => {
    const { data } = await getOne('products', { id: event.target.value });

    history.replace({
      pathname: '/deals/create',
      state: {
        record: {
          [event.target.name]: event.target.value,
          ...data,
          original_price: data.price,
          one_per_bidder: data.one_per_bidder,
        },
      },
    });
  };

  return (
    <Create {...props} transform={transform} onFailure={onFailure}>
      <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
        <ReferenceInput
          source="product_id"
          reference="products"
          onChange={onDealProductChange}
          // TODO: Add autocomplete and remove this perPage
          perPage={1000}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="image_url.url" />
        <ImageInput source="image_url" accept="image/*">
          <ImageField source="url" />
        </ImageInput>
        <ArrayInput source="secondary_image_urls">
          <SimpleFormIterator>
            <TextInput source="url" />

            <ImageInput accept="image/*">
              <ImageField source="url" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="title" />
        <RichTextInput source="description"></RichTextInput>
        <NumberInput source="original_price" />
        <StartAt></StartAt>
        <BooleanInput source="active" initialValue={true} />
        <BooleanInput source="mystery" />
        <BooleanInput source="one_per_bidder" />
        <BooleanInput source="only_for_noobs" />
        <NumberInput source="bids_needed" initialValue={0} />
        <NumberInput source="extra_time_to_close" initialValue={10} />
        <NumberInput source="bot_bids_to_schedule" initialValue={10} />
        <NumberInput source="number_of_bots_to_use" initialValue={2} />
        <NumberInput source="price_to_stop_bots" />
        <BooleanInput source="auto_restart" />
      </SimpleForm>
    </Create>
  );
};
