import { Admin, Resource } from 'react-admin';
import CustomLayout from './components/CustomLayout';
import dataProvider from './api/provider';
import { DealList, DealShow, DealEdit, DealCreate } from './resources/deals';
import { UserList, UserEdit, UserCreate } from './resources/users';
import { OrderList, OrderShow, OrderEdit } from './resources/orders';
import { WinList, WinShow } from './resources/wins';
import { ProductList, ProductEdit, ProductCreate } from './resources/products';
import UserIcon from '@material-ui/icons/Group';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ComputerIcon from '@material-ui/icons/Computer';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Dashboard from './Dashboard';

const App = () => (
  <Admin
    dataProvider={dataProvider}
    layout={CustomLayout}
    dashboard={Dashboard}
  >
    <Resource
      name="deals"
      list={DealList}
      edit={DealEdit}
      show={DealShow}
      create={DealCreate}
      icon={LocalOfferIcon}
    />
    <Resource
      name="products"
      list={ProductList}
      edit={ProductEdit}
      create={ProductCreate}
      icon={ComputerIcon}
    />
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      icon={UserIcon}
    />
    <Resource
      name="orders"
      list={OrderList}
      show={OrderShow}
      edit={OrderEdit}
      icon={MonetizationOnIcon}
    />
    <Resource name="deals/available_times"></Resource>
    <Resource name="wins" list={WinList} icon={EmojiEventsIcon} />
  </Admin>
);

export default App;
