async function createFileFromUrl(url) {
  const params = new URLSearchParams();
  params.append('url', url);
  const response = await fetch(
    `${process.env.REACT_APP_PUBLIC_API_URL}/proxy.json?${params.toString()}`,
    { credentials: 'include' }
  );
  const blob = await response.blob();
  const metadata = { type: response.headers.get('Content-Type') };
  const fileNameSegments = new URL(url).pathname.split('/');
  const fileName = fileNameSegments.pop() || fileNameSegments.pop();
  const extension = response.headers.get('Content-Type').split('/').pop();
  const file = new File([blob], `${fileName}.${extension}`, metadata);
  return file;
}

export default async function imageUrlsToFiles(data) {
  let image;
  let secondaryImages;
  let oauthImageUrl;
  let transformedData = data;

  if (data.image_url) {
    if (data.image_url.rawFile) {
      // Uploaded
      const rawFile = data.image_url.rawFile;
      const url = data.image_url.url;

      image = { rawFile, url };
    } else {
      // Just URL, use proxy to fetch it on server to bypass CORS
      const rawFile = await createFileFromUrl(data.image_url.url);
      const preview = URL.createObjectURL(rawFile);

      image = { rawFile, url: preview };
    }
  }

  if (data.secondary_image_urls) {
    secondaryImages = await Promise.all(
      data.secondary_image_urls.map(async (secondaryImage) => {
        const rawFile = await createFileFromUrl(secondaryImage.url);
        const preview = URL.createObjectURL(rawFile);

        return { rawFile, url: preview };
      })
    );
  }

  if (data.oauth_image_url) {
    oauthImageUrl = data.oauth_image_url.url;
  }

  if (image) {
    transformedData = { ...transformedData, image };
  }

  if (secondaryImages) {
    transformedData = { ...transformedData, secondary_images: secondaryImages };
  }

  if (oauthImageUrl) {
    transformedData = { ...transformedData, oauth_image_url: oauthImageUrl };
  }

  return transformedData;
}
