import {
  List,
  Datagrid,
  ImageField,
  TextField,
  BooleanField,
  DateField,
  NumberField,
  Edit,
  Show,
  Create,
  SimpleForm,
  ImageInput,
  BooleanInput,
  useRedirect,
  SelectInput,
  CheckboxGroupInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  DateTimeInput,
  FormDataConsumer,
  useGetOne,
  SaveButton,
  ArrayInput,
  Pagination,
  SimpleFormIterator,
  Toolbar,
  useNotify,
  ReferenceField,
  useRefresh,
  FormWithRedirect,
  TopToolbar,
  Button,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  useCreate,
  useUpdate,
} from 'react-admin';
import { Link, useHistory, useLocation } from 'react-router-dom';
import dataProvider from '../api/provider';
import { useFormState } from 'react-final-form';
import useTransform from '../utils/useTransform';
import useOnSubmitFailure from '../utils/useOnSubmitFailure';
import TruckIcon from '@material-ui/icons/LocalShipping';
import { Typography, Box } from '@material-ui/core';

const { getOne } = dataProvider;

const filters = [
  <TextInput source="users.name" label="User Name" alwaysOn />,
  <SelectInput
    label="Tipo"
    source="users.auto_bot"
    choices={[
      { id: 'true', name: 'Diegos' },
      { id: 'false', name: 'Usuarios' },
    ]}
    alwaysOn
  />,
];

const WinsPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150, 200]} {...props} />
);

export const WinList = (props) => (
  <List
    sort={{ field: 'won_at  ', order: 'DESC' }}
    filters={filters}
    pagination={<WinsPagination />}
    perPage={50}
    {...props}
  >
    <Datagrid>
      <ReferenceField label="Winner" source="winner_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Winner created_at"
        source="winner_id"
        reference="users"
        sortBy="users.created_at"
      >
        <DateField
          source="created_at"
          label="Ganado"
          showTime
          sortBy="users.created_at"
          options={{
            dateStyle: 'medium',
          }}
        />
      </ReferenceField>
      <ReferenceField
        label="Diego"
        source="winner_id"
        reference="users"
        sortable={false}
      >
        <TextField source="auto_bot" />
      </ReferenceField>
      <ReferenceField label="Deal" source="deal_id" reference="deals">
        <TextField source="title" />
      </ReferenceField>
      <NumberField
        source="price"
        label="Pesos"
        options={{ style: 'currency', currency: 'MXN' }}
      />
      <DateField
        source="won_at"
        label="Ganado"
        showTime
        sortBy="won_at"
        options={{
          dateStyle: 'full',
          timeStyle: 'medium',
          hour12: true,
        }}
      />
    </Datagrid>
  </List>
);
